import React, {useState, useEffect, useRef} from "react";
import {Link, useLoaderData, useNavigate} from "react-router-dom";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment'
import SliderComponent from "../components/carousel/SliderComponent"
import ProgramCard from "../components/cards/ProgramCard"
import HirekFooldalCard from "../components/cards/HirekFooldalCard"
import ava_logo_fekete from "../assets/media/logos/ava_logo_fekete.svg"
import ava_logo_fekete_h from "../assets/media/logos/ava_logo_fekete_h.svg"
import bezaras from "../assets/media/icons/bezaras ikon.svg"
import list_icon from "../assets/media/icons/list icon.svg"
import subtract from "../assets/media/icons/Subtract.svg"
import nka from "../assets/media/logos/nka_2023.svg"
import eu_tamogatas from "../assets/media/logos/Eu tamogatas.svg"
import {
    MDBContainer,
    MDBCol,
    MDBRow
} from 'mdb-react-ui-kit';
import {useSelector} from "react-redux";
import {getEventPlaceUrl} from "../util/get-event-place-url";
import {getEventPlace} from "../util/event-place";
import { pdfjs } from "react-pdf";
import CanvasFlipBook from "react-pageflip";
import Carousel from "react-multi-carousel";
import {formatEventDate} from "../util/format-event-date";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const HomePage = () => {

    const navigate = useNavigate();

    const [moreProductsAvailable, setMoreProductsAvailable] = useState(true);
    const [actuelleDate, setActuelleDate] = useState(new Date());
    const [isActuelleProgram, setIsActuelleProgram] = useState(false);
    const [direction, setDirection] = useState(window.outerWidth <= 810 ? "vertical" : "horizontal");
    const [onMouseOver, setOnMouseOver] = useState(false);
    const [pdfPages, setPDFPages] = useState([]);
    const [ showPdf, setShowPdf ] = useState(false);
    const [ showRoomPdf, setShowRoomPdf ] = useState(false);
    const [roomPdfPages, setRoomPDFPages] = useState([]);
    const [ catalogLoaded, setCatalogLoaded ] = useState(false);
    const [ programSheetLoaded, setProgramSheetLoaded ] = useState(false);

    const {articles, eventPlace, guestEvents, homePage} = useSelector(state => state)

    const closePdf = e => {
        if (e.target.className === 'pdf-viewer-container') {
            setShowPdf(false);
        }
    }

    const closeRoomPdf = e => {
        if (e.target.className === 'room-pdf-viewer-container') {
            setShowRoomPdf(false);
        }
    }

    useEffect(() => {
        setTimeout(() => window.scrollTo({
            top: 0,
            behavior: "smooth"
        }), 100);
    }, []);

    const getPDF = async (url) => {
        //
        // Asynchronous download PDF
        //
        const loadingTask = pdfjs.getDocument(url);
        return await loadingTask.promise;
    }

    const renderPage = async (pdf, pageNumber, prefix = "") => {

        //
        // Fetch the first page
        //
        const page = await pdf.getPage(pageNumber + 1);
        const scale = 2.0;
        const viewport = page.getViewport({ scale });
        // Support HiDPI-screens.
        const outputScale = window.devicePixelRatio || 1;

        //
        // Prepare canvas using PDF page dimensions
        //
        const canvas = document.getElementById(`${prefix}pdf-canvas-page-${ pageNumber }`);
        if (!canvas) {
            return;
        }
        const context = canvas.getContext("2d");

        canvas.width = Math.floor(viewport.width * outputScale);
        canvas.height = Math.floor(viewport.height * outputScale);

        canvas.style.width = Math.floor(viewport.width) + "px";
        canvas.style.height = Math.floor(viewport.height) + "px";

        const transform = outputScale !== 1
            ? [outputScale, 0, 0, outputScale, 0, 0]
            : null;

        const renderContext = {
            canvasContext: context,
            transform,
            viewport,
        };
        page.render(renderContext);
    }

    const loadPDF = async (pdf, prefix = "") => {
        for (let i = 0; i < pdf.numPages; i++) {
            await renderPage(pdf, i, prefix);
        }
    }

    const loadPages = (pdf) => {
        const pages = [];
        for (let i = 0; i < pdf.numPages; i++) {
            pages.push(0);
        }
        setPDFPages(pages);
        setTimeout(() => {
            loadPDF(pdf);
        }, 200)
    }

    const loadRoomPages = (pdf) => {
        const pages = [];
        for (let i = 0; i < pdf.numPages; i++) {
            pages.push(0);
        }
        setRoomPDFPages(pages);
        setTimeout(() => {
            loadPDF(pdf, "room-");
        }, 200)
    }

    const loadProgramfuzet = () => {
        if (programSheetLoaded) {
            return;
        }
        setProgramSheetLoaded(true);
        getPDF(homePage?.programme?.pdf).then(pdf => {
            loadPages(pdf);
        });
    }

    const loadKatalogus = () => {
        if (catalogLoaded) {
            return;
        }
        setCatalogLoaded(true);
        getPDF(homePage?.hallRental?.pdf).then(pdf => {
            loadRoomPages(pdf);
        })
    }

    const handleWindowSizeChange = () => {
        setDirection(window.visualViewport.width <= 810 ? "vertical" : "horizontal");
    };

    useEffect(() => {
        window.visualViewport.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.visualViewport.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);


    const loadMorePrograms = () => {
        if (getEventPlace(eventPlace.id) === `galeria`) {
            navigate('/tovabbi-kiallitasok');
        } else {
            navigate('/fixprogramok/kovetkezoprogramok');
            setMoreProductsAvailable(true);
        }
    };


    const loadMoreHirek = () => {
        navigate('/hirek/hirek')
    };


    const mark = guestEvents?.filter(datum => moment(Date(datum.dateFrom)).format('MM') === moment().format('MM')).map(k => {
        return (moment.unix(k.dateFrom)).format("DD-MM-YYYY")
    })

    let recurringIds = [];
    const filteredEvents = guestEvents?.filter(e => {
        if (e.recurrenceId === null) {
            return true;
        }
        if (recurringIds.includes(e.recurrenceId)) {
            return false;
        }
        recurringIds.push(e.recurrenceId);
        return true;
    });

    const responsive = {
        desktop: {
            breakpoint: { max: 4000, min: 0 },
            items: 1,
            partialVisibilityGutter: 1
        }
    };

    let h = (window.innerHeight * 0.9);
    let w = h / (1870 / 1077);

    if (window.innerWidth < window.innerHeight) {
        w = (window.innerWidth * 0.9) * 0.5;
        h = w * (1870 / 1077);
    }

    let rh = (window.innerHeight * 0.9);
    let rw = h / (1190 / 839);

    if (window.innerWidth < window.innerHeight) {
        rw = (window.innerWidth * 0.9) * 0.5;
        rh = rw * (1190 / 839);
    }


    return (

        <div
            className={`homepage ${guestEvents.length === 0 ? `homepage--hide` : ``}`}>
            <section className="slide-section" id="slide-section">
                <MDBContainer style={{maxWidth: "1920px"}} className='p-0'>
                    <div className="first-div" onClick={(e) => {
                        if (isActuelleProgram && !document.querySelector('.react-calendar__month-view').contains(e.target)) {
                            setActuelleDate(undefined);
                            setIsActuelleProgram(false)
                        }
                    }}>
                        <SliderComponent kiemeltProgramok={guestEvents.filter(e => e.recurrenceId === null)} blurImage={isActuelleProgram}/>
                        {guestEvents && guestEvents.length > 0 && (
                            <div className="calendar-div d-none d-lg-flex">
                                <Calendar minDetail='year' selectRange={false}
                                          formatMonthYear={(locale, date) => moment(date).format("MMMM")}
                                          maxDate={new Date(new Date().getTime() + (6 * 24 * 60 * 60 * 1000))}
                                          minDate={new Date()}
                                          onClickMonth={(value) => alert('New date is: ', value)}
                                          showNeighboringMonth={true}
                                          showFixedNumberOfWeeks={true}
                                          onChange={(date) => {
                                              if (isActuelleProgram && moment(date).format("DD-MM-YYYY") === moment(actuelleDate).format("DD-MM-YYYY")) {
                                                  setActuelleDate(undefined);
                                                  setIsActuelleProgram(false);
                                                  return;
                                              }
                                              if (mark.find(x => x === moment(date).format("DD-MM-YYYY"))) {
                                                  setActuelleDate(date);
                                                  setIsActuelleProgram(true)
                                              } else {
                                                  setActuelleDate(undefined);
                                                  setIsActuelleProgram(false);
                                              }
                                          }}
                                          tileClassName={({date, view}) => {
                                              if (mark.find(x => x === moment(date).format("DD-MM-YYYY"))) {
                                                  if (moment(date).format("DD-MM-YYYY") === moment(actuelleDate).format("DD-MM-YYYY") && isActuelleProgram) {
                                                      return 'active-highlight'
                                                  } else {
                                                      return 'highlight'
                                                  }

                                              }
                                          }}/>
                                <button className="datum-search-button" type="button"
                                        onClick={() => navigate('/fixprogramok/kovetkezoprogramok', {state: {dateFrom: actuelleDate}})}>{eventPlace && (
                                    <img
                                        src={require("../assets/media/icons/" + getEventPlace(eventPlace.id) + "_calendar.svg")}
                                        alt="search-ikon"/>)}</button>
                                <button className="navigation-button" type="button"
                                        onClick={() => navigate('/fixprogramok/kovetkezoprogramok', {state: {dateFrom: actuelleDate}})}></button>
                                {isActuelleProgram && (
                                    <div className="program-div">
                                        {guestEvents.filter(datum => moment.unix(datum.dateFrom).format("DD-MM-YYYY") === moment(actuelleDate).format("DD-MM-YYYY")).map((k, n) => {
                                            return (
                                                <div key={n}>
                                                    <div className="active-program-div"/>
                                                    <div className="active-program-card">
                                                        <img src={k.image} className="active-program-background-image"
                                                             alt="program"/>
                                                        <div className="navigate-div"
                                                             onClick={() => navigate('/programok/programdetailpage/' + k.id, {
                                                                 state: {
                                                                     programId: k.id,
                                                                     program: k,
                                                                     categoryName: "Következő programok",
                                                                     linkText: "kovetkezoprogramok"
                                                                 }
                                                             })}/>
                                                        {k.eventTypes.filter(t => t.name === "Ingyenes programok").length === 0 ?
                                                            <div className="ikon-div" onClick={() => {
                                                                if (k.ticketPrice > 0 && k.ticketPrice !== null && k.ticketPurchaseLink !== null) {
                                                                    window.open(k.ticketPurchaseLink, '_blank')
                                                                } else {
                                                                    navigate('/programok/programdetailpage/' + k.id, {
                                                                        state: {
                                                                            programId: k.id,
                                                                            program: k,
                                                                            categoryName: "Következő programok",
                                                                            linkText: "kovetkezoprogramok"
                                                                        }
                                                                    })
                                                                }
                                                            }}>
                                                                {eventPlace !== undefined && (
                                                                    <img
                                                                        src={k.ticketPrice === 0 || k.ticketPrice === null ? require("../assets/media/icons/" + getEventPlace(eventPlace.id) + "_ingyenes_ikon.svg") : require("../assets/media/icons/" + getEventPlace(eventPlace.id) + "_program_ikon.svg")}
                                                                        className="program-ikon-image" alt=""
                                                                        style={{opacity: k.ticketPurchaseLink !== null ? "1" : "0.5"}}/>
                                                                )}
                                                            </div> : null}
                                                        <div className="bezaras-div" onClick={() => {
                                                            setActuelleDate(undefined);
                                                            setIsActuelleProgram(false)
                                                        }}>
                                                            <img src={bezaras} className="bezaras-ikon-image"
                                                                 alt="bezaras"/>
                                                        </div>
                                                        <div className="text-div">
                                                            <div className="time">
                                                                <span
                                                                    className="time-text">{ moment.unix(k.dateFrom).locale('hu').format('MMMM DD., ') + formatEventDate(k) }</span>
                                                            </div>
                                                            <p>{k.title}</p>
                                                            <span>{k.location?.name}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })[0]}
                                    </div>
                                )}
                            </div>

                        )}


                    </div>
                </MDBContainer>
            </section>
            {guestEvents.length > 0 && (
                <section className="kovetkezo-programok-section" id="kovetkezo-programo-section">
                    <MDBContainer style={{maxWidth: "1008px"}} className='px-0'>
                        <div className="program-title" onClick={loadMorePrograms} style={{cursor: "pointer"}}>
                            <span>{getEventPlace(eventPlace.id) === "galeria" ? "Kiállítások" : "Következő programok"}</span>
                        </div>

                        { direction === "vertical" ? (
                            <Carousel
                                additionalTransfrom={0}
                                swipeable={true}
                                draggable={false}
                                showDots={false}
                                responsive={responsive}
                                infinite={true}
                                renderArrowsWhenDisabled={true}
                                renderDotsOutside={true}
                                slidesToSlide={1}
                                centerMode={false}
                                keyBoardControl={true}
                                customTransition="transform 1000ms ease-in-out"
                                removeArrowOnDeviceType={guestEvents.length > 1 ? [] : ["desktop"]}
                                transitionDuration={1000}
                                containerClass={"carousel-container"}
                            >
                                {filteredEvents?.filter((k, index) => index < 6).map((k, n) => {
                                    return (
                                        <MDBRow className="programok-row">
                                            <MDBCol xl="4" lg="4" md="6" size="12" className="programok-col" key={n}>
                                                <ProgramCard msg={k} kovetkezoprogramok={true} categoryName={"Következő programok"} linkText={"kovetkezoprogramok"}/>
                                            </MDBCol>
                                        </MDBRow>
                                    )
                                })}
                            </Carousel>
                        ) : (
                            <MDBRow className="programok-row">
                                {filteredEvents?.filter((k, index) => index < 6).map((k, n) => {
                                    return (
                                        <MDBCol xl="4" lg="4" md="6" size="12" className="programok-col"
                                                key={n}>
                                            <ProgramCard msg={k} kovetkezoprogramok={true}
                                                         categoryName={"Következő programok"}
                                                         linkText={"kovetkezoprogramok"}/>
                                        </MDBCol>
                                    )
                                })}

                            </MDBRow>
                        )}

                        {moreProductsAvailable && (
                            <div className="loadmore-div">
                                <button type="button" className="muster-button kiemelt-next" onClick={loadMorePrograms}>
                                    <span>Továbbiak</span>
                                </button>
                            </div>)}
                    </MDBContainer>
                </section>
            )}
            {getEventPlace(eventPlace.id) === "ava" && (<>
                {homePage && homePage?.programme.pdf !== null && (
                    <section className="programfuzet-section" id="programfuzet-section">
                        <MDBContainer style={{maxWidth: "1008px"}} className="programfuzet-div">

                            <div className="programfuzet-text">
                                <p>Programfüzet</p>
                                <span className="description">{homePage?.programme.description}</span>
                                <button type="button" className="muster-button small elsodleges application"
                                        onClick={() => { loadProgramfuzet(); setShowPdf(true) }}>
                                    <span>LAPOZZON BELE</span></button>
                            </div>
                            {direction === "horizontal" && (
                                <img src={homePage?.programme.image} alt="programfuzet"
                                     onClick={() => { loadProgramfuzet(); setShowPdf(true) }}
                                     style={{cursor: "pointer"}}/>
                            )}

                        </MDBContainer>

                    </section>
                )}
            </>)}

            {articles.length > 0 && (
                <section className="hirek-section" id="hirek-section">
                    <MDBContainer style={{maxWidth: "1008px"}} className='px-0 hirek-div'>
                        <div className="hirek-title" onClick={loadMoreHirek} style={{cursor: "pointer"}}>
                            <span>Hírek</span>
                        </div>
                        { direction === `vertical` ? (
                            <Carousel
                                additionalTransfrom={0}
                                swipeable={true}
                                draggable={false}
                                showDots={false}
                                responsive={responsive}
                                infinite={true}
                                renderArrowsWhenDisabled={true}
                                renderDotsOutside={true}
                                slidesToSlide={1}
                                centerMode={false}
                                keyBoardControl={true}
                                customTransition="transform 1000ms ease-in-out"
                                removeArrowOnDeviceType={articles.length > 1 ? [] : ["desktop"]}
                                transitionDuration={1000}
                                containerClass={"carousel-container"}
                            >
                                {articles.filter((n, index) => index < 3).map((k, n) => {
                                    return (
                                        <MDBRow className="hirek-row">
                                            <MDBCol xl="4" lg="4" md="6" size="12" className="hirek-col" key={n}>
                                                <HirekFooldalCard msg={k}/>
                                            </MDBCol>
                                        </MDBRow>
                                    )
                                })}
                            </Carousel>
                        ) : (
                            <MDBRow className="hirek-row">
                                {articles && (<>
                                        {articles.filter((n, index) => index < 3).map((k, n) => {
                                            return (
                                                <MDBCol xl="4" lg="4" md="6" size="12" className="hirek-col" key={n}>
                                                    <HirekFooldalCard msg={k}/>
                                                </MDBCol>
                                            )
                                        })}
                                    </>
                                )}
                            </MDBRow>
                        ) }
                        <div className="loadmore-div">
                            <button type="button" className="muster-button kiemelt-next"
                                    onClick={loadMoreHirek}><span>Továbbiak </span>
                            </button>
                        </div>
                    </MDBContainer>
                </section>
            )}

            {getEventPlace(eventPlace.id) === "ava" && (<>
                <section className="tbkartya-section" id="tbkartya-section">
                    <MDBContainer style={{maxWidth: "1008px"}} className='p-0'>
                        <div className="tbkartya-div" onClick={() => navigate('/tbkartya')}
                             style={{cursor: "pointer"}}>
                            <div className="info-div">
                                <span className="text">Tatabánya Kártya</span>
                                <button className="muster-button small elsodleges jegyvasarlas"
                                        onClick={() => navigate('/tbkartya')}><span>TOVÁBBI INFORMÁCIÓK</span></button>
                            </div>
                        </div>
                    </MDBContainer>
                </section>
            </>)}
            {getEventPlace(eventPlace.id) === "ava" && homePage?.hallRental?.pdf !== null && (
                <section className="helyszin-section" id="helyszin-section">
                    <MDBContainer fluid className='px-0 helyszin-div'>
                        <div className="image-div"/>
                        <MDBContainer style={{maxWidth: "1008px"}} className='px-0 '>
                            <div className="info-div">
                                <p>A megfelelő helyszínt keresi rendezvényeinek?</p>
                                <span className="helyszin-text">Az Agora Nonprofit Kft. intézményei mintegy negyven különböző bérelhető termet kínálnak az érdeklődők számára.</span>
                                <span className="helyszin-text">Legyen szó kulturális, céges, sport-, baráti vagy családi rendezvényről,
                  nálunk biztosan megtalálja az igényeinek megfelelő méretű és felszereltségű helyiséget.</span>
                                <span className="list-text"><img src={list_icon} alt="list"
                                                                 style={{marginRight: "10px"}}/> 40 bérelhető terem</span>
                                <span className="list-text"><img src={list_icon} alt="list"
                                                                 style={{marginRight: "10px"}}/> 10-től 440 főig</span>
                                <span className="list-text"><img src={list_icon} alt="list"
                                                                 style={{marginRight: "10px"}}/> hang-, vetítés és fénytechnika</span>
                                <span className="list-text"><img src={list_icon} alt="list"
                                                                 style={{marginRight: "10px"}}/> szakmai és műszaki támogatás</span>
                                <span className="button-text">Tekintse át katalógusunkat!</span>
                                <button type="button" className="muster-button small elsodleges jegyvasarlas"
                                        onClick={() => { loadKatalogus(); setShowRoomPdf(true) }}>
                                    <span>TERMEINK</span></button>
                            </div>
                        </MDBContainer>
                    </MDBContainer>
                </section>
            )}


            <section className="hirlevel-section" id="hirlevel-section">
                <div className="hirlevel-div">
                    <p>Agora hírlevél</p>
                    <span className="hirlevel-text">Iratkozzon fel az Agora hírlevelére és értesüljön elsőként programjainkról!</span>
                    <button type="button" className="muster-button small elsodleges jegyvasarlas"
                            onClick={() => navigate('/hirlevelpage')}><span>FELIRATKOZOM</span></button>
                </div>
            </section>


                {((getEventPlace(eventPlace.id) === `ava` && homePage && homePage.youtube) || (getEventPlace(eventPlace.id) !== `ava` && eventPlace.youtubeEmbedUrlHome)) && (
                    <section className="video-section" id="video-section">
                        <MDBContainer style={{maxWidth: "1008px"}} className='p-0'>
                            <div className="video-div">
                                {getEventPlace(eventPlace.id) === "ava" && window.outerWidth <= 810 && (
                                    <div className="tartalom-div">
                                        <p>{homePage?.youtube.title}</p>
                                        <span>{homePage?.youtube.description}</span>
                                    </div>
                                )}

                                { getEventPlace(eventPlace.id) === "ava" ? (
                                    <iframe src={homePage.youtube.embedLink + "?autoplay=0&controls=1&loop=0"}
                                            height={window.innerWidth / 1920 > 1 ? 410 : window.innerWidth > 810 ? (window.innerWidth / 1920) * 410 : (window.innerWidth - 50) * 9 / 16}
                                            width={window.innerWidth / 1920 > 1 ?
                                                727 : window.innerWidth > 810 ? (window.innerWidth / 1920) * 727 : window.innerWidth - 50}
                                            allow="autoplay" title="myFrame"></iframe>
                                ) : (
                                    <iframe src={eventPlace.youtubeEmbedUrlHome + "?autoplay=0&controls=1&loop=0"} style={{ aspectRatio: `16 / 9`, width: `100%`}}
                                            allow="autoplay" title="myFrame"></iframe>
                                )}
                                {getEventPlace(eventPlace.id) === "ava" && window.outerWidth > 810 && (
                                    <div className="tartalom-div">
                                        <p>{homePage?.youtube.title}</p>
                                        <span>{homePage?.youtube.description}</span>
                                    </div>
                                )}
                            </div>
                        </MDBContainer>
                    </section>
                )}

            {(getEventPlace(eventPlace.id) === "ava" || getEventPlace(eventPlace.id) === "galeria") && (<>
                <section className="agoraapp-section" id="agoraapp-section">
                    <MDBContainer fluid className='agoraap-container'>
                        {window.outerWidth > 810 ? <>
                                <div className="mobile-image"/>
                                <div className="agoraapp-div">
                                    <p>A Vértes Agorája applikáció</p>
                                    <span className="text">Értesülj elsőként Tatabánya és a térség legújabb
                programjairól és élvezd az applikáció által nyújtott egyedi lehetőségeket!</span>
                                    <span className="list-text"><img src={subtract} alt="subtract"/> folyamatosan frissülő programkínálat</span>
                                    <span className="list-text"><img src={subtract} alt="subtract"/> egyedülálló kedvezmények</span>
                                    <span className="list-text"><img src={subtract} alt="subtract"/> elsőbbségi jegyvásárlás</span>
                                    <span className="list-text"><img src={subtract}
                                                                     alt="subtract"/> early bird jegyek</span>
                                    <button className="muster-button small elsodleges application mt-4"
                                            onClick={() => window.open("https://linkgenerator.appentum.pro/avertesagoraja", '_blank')}>
                                        <span>ALKALMAZÁS LETÖLTÉSE</span></button>
                                </div>
                            </> :
                            <div className="agoraapp-div-respo">
                                <p>A Vértes Agorája applikáció</p>
                                <span className="text">Értesülj elsőként Tatabánya és a térség legújabb
                programjairól és élvezd az applikáció által nyújtott egyedi lehetőségeket!</span>
                                <span className="list-text"><img src={subtract} alt="subtract"/> folyamatosan frissülő programkínálat</span>
                                <span className="list-text"><img src={subtract} alt="subtract"/> egyedülálló kedvezmények</span>
                                <span className="list-text"><img src={subtract} alt="subtract"/> elsőbbségi jegyvásárlás</span>
                                <span className="list-text"><img src={subtract}
                                                                 alt="subtract"/> early bird jegyek</span>
                                <button className="muster-button elsodleges application mt-4"
                                        onClick={() => window.open("https://linkgenerator.appentum.pro/avertesagoraja", '_blank')}>
                                    <span>ALKALMAZÁS LETÖLTÉSE</span></button>
                            </div>}
                    </MDBContainer>
                </section>

            </>)}
            {getEventPlace(eventPlace.id) !== "ava" && (
                <>
                    {direction === `horizontal` ?
                        <section className="agora-link">
                            <MDBContainer style={{maxWidth: "1008px"}} className='p-0'>
                                <div className="agora-link-div">
                                    <img className="ava-fekete-logo"
                                         src={onMouseOver ? ava_logo_fekete_h : ava_logo_fekete} alt=""
                                         style={{cursor: "pointer"}} onMouseOver={() => setOnMouseOver(true)}
                                         onMouseLeave={() => setOnMouseOver(false)}/>
                                    <div className="agora-link-text">
                                        <span>Tovább A Vértes Agorája oldalára.</span>
                                    </div>
                                    <div className="loadmore-div">
                                        <Link to={getEventPlaceUrl('/', 1)}>
                                            <button type="button" className="muster-button kiemelt-next">
                                                <span>Tovább</span>
                                            </button>
                                        </Link>
                                    </div>

                                </div>
                            </MDBContainer>
                        </section> :
                        <section className="agora-link-respo">
                            <MDBContainer style={{maxWidth: "1008px"}} className='p-0'>
                                <div className="agora-link-div">
                                    <div className="agora-link-text">
                                        <span>Tovább A Vértes Agorája oldalára.</span>
                                    </div>
                                    <div className="loadmore-div">
                                        <Link to={getEventPlaceUrl('/', 1)} target="_blank">
                                            <button type="button" className="muster-button kiemelt-next">
                                                <span>Tovább</span>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </MDBContainer>
                        </section>}
                </>
            )}
            <section className="tamogatasok-section" id="tamogatasok-section">
                <MDBContainer style={{maxWidth: "1920px"}} className='p-0 tamogatasok-container'>
                    <div className="tamogatasok-div">
                        <Link to="/infos/palyazatok/hacs_tamogatasok"><img className="eu-image" src={eu_tamogatas}
                                                                           alt="eu_tamogatas"/></Link>
                        <a href="https://nka.hu" target="_blank"><img className="nka-image" src={nka}
                                                                      alt="eu_tamogatas"/></a>
                    </div>
                </MDBContainer>
            </section>

            <div className="pdf-viewer-container" onClick={closePdf}
                 style={{visibility: showPdf ? `visible` : `hidden`, pointerEvents: showPdf ? `all` : `none`}}>
                <div className="pdf-viewer">
                    <CanvasFlipBook width={w} height={h} startPage={0} showCover={true} usePortrait={false}>
                        {pdfPages.map((page, i) => <canvas id={`pdf-canvas-page-${i}`}/>)}
                    </CanvasFlipBook>
                </div>
            </div>

            <div className="room-pdf-viewer-container" onClick={closeRoomPdf}
                 style={{visibility: showRoomPdf ? `visible` : `hidden`, pointerEvents: showRoomPdf ? `all` : `none`}}>
                <div className="pdf-viewer">
                    <CanvasFlipBook width={rw} height={rh} startPage={0} showCover={true} usePortrait={false}>
                        {roomPdfPages.map((page, i) => <canvas id={`room-pdf-canvas-page-${i}`}/>)}
                    </CanvasFlipBook>
                </div>
            </div>
        </div>
    );
};

export default HomePage;
