import React, {useEffect, useState} from "react";
import 'react-calendar/dist/Calendar.css';
import { MDBContainer } from 'mdb-react-ui-kit';
import {useDispatch, useSelector} from "react-redux";
import {setSession} from "../redux/actions/setSession";
import {useNavigate} from "react-router-dom";


const LoginPage = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { session } = useSelector(state => state);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);

  useEffect(() => {
    if (session.token.length === 0) {
      navigate("/belepes");
    }
  }, [])

  const logout = async () => {

    dispatch(setSession({token: ``}));
    navigate('/belepes');

  }

  const deleteUser = async () => {
    let failed = false;
    let response;
    try {
      response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }api/v1.0/users/me`, {
        method: "DELETE",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Platform-Id": 3,
          "Authorization": `Bearer ${session.token}`
        }
      });
    } catch {
      failed = true
    }

    if (failed || response.status !== 204) {
      alert('Hiba történt a törlés közben! Kérjük, próbálja újra később!');
      return;
    }

    dispatch(setSession({token: ``}));
    navigate('/belepes');
    alert('A fiók sikeresen törlésre került!');

  }

  return (
    <>
    <section className="login-page">
      <MDBContainer style={{maxWidth: "1008px"}}>
        <div className="login-form">
          <div className="login-form__title">Ez A Vértes Agorája fiókod!</div>
          <div className="login-form__description">Lehetőséged van A Vértes Agorája mobilapplikációs felhasználód törlésére. Ebben az esetben minden adatodat töröljük, amelyet megadtál az alkalmazásban.</div>
          <div className="login-form__buttons">
            <button onClick={deleteUser}>Törlés</button>
            <button onClick={logout}>Kilépés</button>
          </div>
        </div>
      </MDBContainer>
    </section>
    </>
  );
};

export default LoginPage;
