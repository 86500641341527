import moment from "moment/moment";

export function formatEventDate(event) {
    if (event.all_day) {
        return `egész nap`
    } else {
        if (moment.unix(event.dateFrom).locale('hu').format('MM-DD') !== moment.unix(event.dateTo).locale('hu').format('MM-DD')) {
            return moment.unix(event.dateFrom).locale('hu').format('HH:mm');
        } else {
            return moment.unix(event.dateFrom).locale('hu').format('HH:mm') + ` - ` + moment.unix(event.dateTo).locale('hu').format('HH:mm');
        }
    }
}