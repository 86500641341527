import React, {useEffect, useState} from "react";
import 'react-calendar/dist/Calendar.css';
import { MDBContainer } from 'mdb-react-ui-kit';
import {useDispatch} from "react-redux";
import {setSession} from "../redux/actions/setSession";
import {useNavigate} from "react-router-dom";
import FacebookLogin from "@greatsumini/react-facebook-login";
import AppleLogin from "react-apple-login";

let googleLoginWrapperButton = null
let triedAppleLogin = false

const LoginPage = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });

    if (window.location.hash.length > 1) {
      const hash = {};
      const pairs = window.location.hash.substring(1).split("&");
      for (const pair of pairs) {
        const [ key, value ] = pair.split("=");
        hash[key] = value;
      }
      if (hash.id_token) {
        appleLogin(hash.id_token);
      }
    }

  }, []);

  const googleLogin = async (idToken) => {
    let failed = false;
    let response;

    try {
      response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }api/v1.0/auth/social/google/signin`, {
        method: "POST",
        body: JSON.stringify({
          deviceId: `browser`,
          idToken: idToken
        }),
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Platform-Id": 3
        }
      });
    } catch {
      failed = true
    }

    if (failed || response.status !== 200) {
      alert('Nem volt sikeres a bejelentkezés!');
      return;
    }

    const body = await response.json();
    dispatch(setSession({token: body.accessToken}));
    navigate('/profil');
  }

  const facebookLogin = async (accessToken) => {
    let failed = false;
    let response;

    try {
      response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }api/v1.0/auth/social/facebook/signin`, {
        method: "POST",
        body: JSON.stringify({
          deviceId: `browser`,
          accessToken: accessToken
        }),
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Platform-Id": 3
        }
      });
    } catch {
      failed = true
    }

    if (failed || response.status !== 200) {
      alert('Nem volt sikeres a bejelentkezés!');
      return;
    }

    const body = await response.json();
    dispatch(setSession({token: body.accessToken}));
    navigate('/profil');
  }

  const appleLogin = async (authorizationCode) => {
    let failed = false;
    let response;

    try {
      response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }api/v1.0/auth/social/apple/signin`, {
        method: "POST",
        body: JSON.stringify({
          deviceId: `browser`,
          authorizationCode: authorizationCode
        }),
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Platform-Id": 3
        }
      });
    } catch {
      failed = true
    }

    if (failed || response.status !== 200) {
      alert('Nem volt sikeres a bejelentkezés!');
      return;
    }

    const body = await response.json();
    dispatch(setSession({token: body.accessToken}));
    navigate('/profil');
  }

  const login = async () => {
    let failed = false;
    let response;
    try {
      response = await fetch(`${ process.env.REACT_APP_BACKEND_URL }api/v1.0/auth/login`, {
        method: "POST",
        body: JSON.stringify({
          deviceId: `browser`,
          email: email,
          password: password,
        }),
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Platform-Id": 3
        }
      });
    } catch {
      failed = true
    }

    if (failed || response.status !== 200) {
      alert('Nem volt sikeres a bejelentkezés!');
      return;
    }

    const body = await response.json();
    dispatch(setSession({token: body.accessToken}));
    navigate('/profil');

  }

  const handleGoogleButtonClick = () => {

    const google = window.google

    if (googleLoginWrapperButton === null) {

      google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        ux_mode: "popup",
        callback: handleGoogleLogin
      });

      const googleLoginWrapper = document.createElement("div");
      // Or you can simple hide it in CSS rule for custom-google-button
      googleLoginWrapper.style.display = "none";
      googleLoginWrapper.classList.add("custom-google-button");

      // Add the wrapper to body
      document.body.appendChild(googleLoginWrapper);

      // Use GSI javascript api to render the button inside our wrapper
      // You can ignore the properties because this button will not appear
      google.accounts.id.renderButton(googleLoginWrapper, {
        type: "icon",
        width: "200",
      });

      googleLoginWrapperButton = googleLoginWrapper.querySelector("div[role=button]");

    }

    googleLoginWrapperButton?.click()

  }

  const handleGoogleLogin = async (credentials) => {
    await googleLogin(credentials.credential)
  }

  const handleFacebookLogin = async (response) => {
    await facebookLogin(response.accessToken)
  }

  const handleFacebookFail = (error) => {
    alert(`Sajnos nem sikerült a bejelentkezés!`);
  }

  const handleAppleLogin = async (response) => {
    if (triedAppleLogin) {
      return;
    }

    triedAppleLogin = true;
    // await appleLogin(response.code)
  }

  return (
    <>
    <section className="login-page">
      <MDBContainer style={{maxWidth: "1008px"}}>
        <div className="login-form">
          <div className="login-form__title">Ez A Vértes Agorája fiókod!</div>
          <div className="form-field">
            <label>E-mail cím</label>
            <input type="email" placeholder="E-mail cím" value={email} onChange={e => setEmail(e.target.value)} />
          </div>
          <div className="form-field">
            <label>Jelszó</label>
            <input type="password" placeholder="Jelszó" value={password} onChange={e => setPassword(e.target.value)}/>
          </div>
          <button onClick={login}>Küldés</button>
        </div>
        <div className="login-footer">
          <div className="social-button" onClick={ handleGoogleButtonClick }>
            <div className="social-button__icon">
              <img src="/social-google.svg"/>
            </div>
            <div className="social-button__caption">FOLYTATÁS GOOGLE FIÓKKAL</div>
          </div>

          <FacebookLogin
              appId={ process.env.REACT_APP_FACEBOOK_APP_ID || `` }
              onSuccess={ handleFacebookLogin }
              onFail={ handleFacebookFail }
              render={({ onClick, logout }) => (
                  <div className="social-button" onClick={onClick} >
                    <div className="social-button__icon">
                      <img src="/social-facebook.svg"/>
                    </div>
                    <div className="social-button__caption">FOLYTATÁS FACEBOOK FIÓKKAL</div>
                  </div>
              )}
          />

          <AppleLogin clientId={process.env.REACT_APP_APPLE_CLIENT_ID || ``}
                      redirectURI={`${process.env.REACT_APP_CMS_URL}/belepes`} responseType="code id_token" responseMode="fragment"
                      callback={handleAppleLogin} render={renderFunctions => (
              <div className="social-button" onClick={ renderFunctions.onClick }>
                <div className="social-button__icon">
                  <img src="/social-apple.svg"/>
                </div>
                <div className="social-button__caption">FOLYTATÁS APPLE FIÓKKAL</div>
              </div>
          )}/>

        </div>
      </MDBContainer>
    </section>
    </>
  );
};

export default LoginPage;
