import { useEffect, useState } from "react";
import {Link, useNavigate} from "react-router-dom";
import * as React from "react";
import { MenuList } from '../../../data/NavbarMenuList'
import search from "../../../assets/media/icons/Kereses ikon.svg"
import hamburger from "../../../assets/media/icons/hamburger.svg"
import close from "../../../assets/media/icons/close.svg"
import next from "../../../assets/media/icons/tovabb nyil.svg"
import back from "../../../assets/media/icons/vissza nyil.svg"
import Menubar from "../../menubar/Menubar";
import {
  MDBContainer,
  MDBNavbar,
  MDBIcon,
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
} from 'mdb-react-ui-kit';
import Cookies from 'universal-cookie';
import {useDispatch, useSelector} from "react-redux";
import {getEventPlaceUrl} from "../../../util/get-event-place-url";
import {getEventPlace} from "../../../util/event-place";
import {setSearchFunction} from "../../../redux/actions/setSearchFunction";
import {setProgramCategoryId} from "../../../redux/actions/setProgramCategoryId";
const cookies = new Cookies();


const NavbarComponentMultilevel = (props) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // STATES
  const [pathname, setPathname] = useState("/" + window.location.pathname.split("/")[1]);
  const [pathnameMiddle, setPathnameMiddle] = useState("/" + window.location.pathname.split("/")[2]);
  const [pathnameEnd, setPathnameEnd] = useState("/" + window.location.pathname.split("/")[window.location.pathname.split("/").length-1]);
  const [direction2, setDirection2] = useState(window.outerWidth);
  const [direction, setDirection] = useState(window.outerWidth <= 810 ? "vertical" : "horizontal");
  const [basicOpen, setBasicOpen] = useState(false);
  const [menuText, setMenuText] = useState("");
  const [subMenuText, setSubMenuText] = useState("");
  const [menuList, setMenuList] = useState([]);
  const [subMenuList, setSubMenuList] = useState([]);
  const [isMenu, setIsMenu] = useState(true);
  const [isSubMenu, setIsSubMenu] = useState(false);
  const [isSubsubMenu, setIsSubsubMenu] = useState(false);

  const { articleCategories, eventPlace, eventTypes, infopages } = useSelector(state => state)

  useEffect(() => {
    setPathname("/" + window.location.pathname.split("/")[1]);
    setPathnameMiddle("/" + window.location.pathname.split("/")[2]);
    setPathnameEnd("/" + window.location.pathname.split("/")[window.location.pathname.split("/").length - 1]);
  }, [window.location.pathname]);



  const getReplaceText = (text) => {
      return text.toLowerCase().replaceAll("ö", "o").replaceAll("ü", "u").replaceAll(".", "").replaceAll("í", "i").replaceAll("ó", "o").replaceAll("ú", "u").replaceAll("é", "e").replaceAll("á", "a").replaceAll("ő", "o").replaceAll("ű", "u").replaceAll(",", "").replaceAll(" ", "_")
  }

  const eventpages = [
    {
      pathnameEnd: '/kovetkezoprogramok',
      name: "Következő programok",
      navigate: 'fixprogramok/kovetkezoprogramok',
      programCategoryId: null,
      onNotAva: true
    },
    ...eventTypes.filter(k => k.featured === true).map(l => ({
      pathnameEnd: '/' + getReplaceText(l.name),
      name: l.name,
      navigate: '/programok/' + l.id,
      programCategoryId: l.id,
      onNotAva: false
    })),
    {
      pathnameEnd: '/allandoprogramok',
      name: "Állandó programok",
      navigate: '/fixprogramok/allandoprogramok',
      programCategoryId: null,
      onNotAva: false
    },
    {
      pathnameEnd: '/archivum',
      name: "Archívum",
      navigate: 'fixprogramok/archivum',
      programCategoryId: null,
      onNotAva: true
    }
  ];

  const handleWindowSizeChange = () => {
    setDirection2(window.visualViewport.width);
  };

  useEffect(() => {
    window.visualViewport.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.visualViewport.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if(basicOpen){
			document.body.style.overflow = 'hidden'

		}else{
			document.body.style.overflow = 'scroll'
		}
  }, [basicOpen]);

  const label = `${direction} menubar`;
  const MockMenuItems = () => (
    <React.Fragment>
      {direction2 > 810 && (
      <div className={direction2 > 810 ? "d-flex justify-content-end align-items-center" : ""}>
        {eventPlace && (
          <Menubar.MenuItem>
            <button onClick={() => { navigate('/home'); window.scrollTo({ top: 0, behavior: "smooth" })}}>
              <img src={require("../../../assets/media/logos/" + getEventPlace(eventPlace.id) + "_logo.svg")} alt="ava-logo" height={direction2 > 1248 ? '33.6' : '26'} loading='lazy'/>
            </button>
          </Menubar.MenuItem>
        )}
      </div>
      )}
      <div className={direction2 > 810 ? "d-flex justify-content-end align-items-center" : ""}>
        {getEventPlace(eventPlace.id) !== "ava" && (
          <Menubar.MenuItem>
            <Menubar.Submenu>
              <Menubar.Submenu.Trigger className={pathname === "/bemutatkozas" || pathname === "/elerhetosegek" ? "active": ""}>

                  {getEventPlace(eventPlace.id) !== "galeria" ?
                  <div className="d-flex align-items-center">
                  Rólunk  <img src={next} alt="tovabb" style={{marginLeft: "5px"}}/>
                  </div> :
                  <div className="d-flex align-items-center">
                  A Kortárs Galériáról  <img src={next} alt="tovabb" style={{marginLeft: "5px"}}/>
                  </div>

                  }
              </Menubar.Submenu.Trigger>
              <Menubar.Submenu.List >
              <Menubar.MenuItem>
                <button onClick={() => {navigate('/bemutatkozas')}} className={pathname === "/bemutatkozas"  ? "active": ""} style={{fontWeight: "300", fontStyle: "normal"}}>
                  Bemutatkozás
                </button>
              </Menubar.MenuItem>
              <Menubar.MenuItem>
                <button onClick={() => navigate('/elerhetosegek')} className={pathname === "/elerhetosegek"  ? "active": ""} style={{fontWeight: "300", fontStyle: "normal"}}>
                  Elérhetőségek
                </button>
              </Menubar.MenuItem>
              </Menubar.Submenu.List>
            </Menubar.Submenu>

          </Menubar.MenuItem>
        )}
        {eventpages && (
          <Menubar.MenuItem>
            {(menuItemProps) => ( <>
              {getEventPlace(eventPlace.id) !== "galeria" ?
                <Menubar.Submenu>
                  <Menubar.Submenu.Trigger
                    className={pathname === "/programok" || pathname === "/fixprogramok" ? "active": ""}
                    {...menuItemProps}
                  >
                    <div className="d-flex align-items-center">
                      Programok  <img src={next} alt="tovabb" style={{marginLeft: "5px"}}/>
                    </div>
                  </Menubar.Submenu.Trigger>

                  <Menubar.Submenu.List >
                  {getEventPlace(eventPlace.id) !== "ava" ? <>
                  {eventpages.filter((k,n) => k.onNotAva).map((l,m) => {
                            return (
                      <Menubar.MenuItem key={m+1}>
                        <button onClick={() => {
                                                    navigate(l.navigate);
                                                    dispatch(setProgramCategoryId(l.programCategoryId));
                                                    cookies.set('programCategoryId', {
                                                      programCategoryId: l.programCategoryId
                                                    }, { path: '/'}
                                                  );
                                                  }}
                                    className={l.pathnameEnd === pathnameEnd  ? "active": ""} style={{fontWeight: "300", fontStyle: "normal"}}>
                        {l.name}
                        </button>
                      </Menubar.MenuItem>
                    )})}
                  </>:
                  <>
                  {eventpages.map((l,m) => {
                            return (
                      <Menubar.MenuItem key={m+1}>
                        <button onClick={() => {
                                                    navigate(l.navigate);
                                                    dispatch(setProgramCategoryId(l.programCategoryId));
                                                    cookies.set('programCategoryId', {
                                                      programCategoryId: l.programCategoryId
                                                    }, { path: '/'}
                                                  );
                                                  }}
                                    className={l.pathnameEnd === pathnameEnd  ? "active": ""} style={{fontWeight: "300", fontStyle: "normal"}}>
                        {l.name}
                        </button>
                      </Menubar.MenuItem>
                    )})}
                  </>}

                  </Menubar.Submenu.List>
                </Menubar.Submenu>
                :
                <Menubar.MenuItem>
                  <button onClick={() => {navigate('fixprogramok/kovetkezoprogramok');  dispatch(setProgramCategoryId(null));
                                                    cookies.set('programCategoryId', {
                                                      programCategoryId: null
                                                    }, { path: '/'}
                                                  );}} className={pathname === "/programok" || pathname === "/fixprogramok" ? "active": ""}
                    {...menuItemProps}>
                    Korábbi kiállítások
                  </button>
                </Menubar.MenuItem>
              }
            </>

            )}
          </Menubar.MenuItem>
        )}

          <Menubar.MenuItem>
            <button onClick={() => { navigate('/hirek/hirek'); }}
                   className={pathname === "/hirek"  ? "active": ""}>
              Hírek
            </button>

          </Menubar.MenuItem>

        {getEventPlace(eventPlace.id) === "ava" && (<>
          {infopages && (
            <Menubar.MenuItem>
              {(menuItemProps) => (
                <Menubar.Submenu>
                  <Menubar.Submenu.Trigger
                    className={pathname === "/infos" || pathname === "/infosfooldal" ? "active": ""}
                    {...menuItemProps}
                  >

                    <div className="d-flex align-items-center">
                     Info<img src={next} alt="tovabb" style={{marginLeft: "5px"}}/>
                    </div>
                  </Menubar.Submenu.Trigger>

                  <Menubar.Submenu.List >
                    {infopages.map((l,m) => {
                      return (
                      <div key={m}>
                        {l.children.length === 0 || l.title === `Rendezvényszervezés, terembérlés` ?
                          <Menubar.MenuItem>
                            { l.pagetype === "DINAMIC" ? (
                                <button onClick={() => {
                                  navigate(`/${ l.source }`);
                                }} className={'/' + l.id === pathnameEnd  ? "active": ""} style={{fontWeight: "300", fontStyle: "normal"}}>
                                  {l.title}
                                </button>
                            ) : (
                                <button onClick={() => {
                                  navigate('/infosfooldal/' + l.id);
                                }} className={'/' + l.id === pathnameEnd ? "active" : ""}
                                        style={{fontWeight: "300", fontStyle: "normal"}}>
                                  {l.title}
                                </button>
                            )}
                          </Menubar.MenuItem>
                            :
                            <Menubar.MenuItem>
                              {(menuItemProps) => (
                                  <Menubar.Submenu>
                                    <Menubar.Submenu.Trigger {...menuItemProps}
                                                             onClick={() => {

                                                               navigate('/infosfooldal/' + l.id);

                                                      }}
                                                          >
                                      <button className={'/' + l.id === pathnameMiddle ? "active" : ""}
                                              style={{display: `flex`, alignItems: `center`, fontWeight: "300", fontStyle: "normal"}}>
                                        {l.title}
                                        <img src={next} alt="tovabb" style={{marginLeft: "5px"}}/>
                                      </button>
                                    </Menubar.Submenu.Trigger>
                                <Menubar.Submenu.List >
                                  {l.children.map((u,v)=> {return (
                                    <Menubar.MenuItem key={v+1} onClick={() => {navigate('/infos/' + getReplaceText(l.title) + '/' + getReplaceText(u.title)); }} >
                                      <button  className={'/infos/' + getReplaceText(l.title) + '/' + getReplaceText(u.title) === window.location.pathname  ? "active": ""} style={{fontWeight: "300", fontStyle: "normal"}}>{u.title}</button>
                                    </Menubar.MenuItem>
                                  )})}
                                </Menubar.Submenu.List>
                              </Menubar.Submenu>
                            )}
                          </Menubar.MenuItem>
                        }
                        </div>
                    )})}
                  </Menubar.Submenu.List>
                </Menubar.Submenu>
              )}
            </Menubar.MenuItem>
          )}
        </>)}
        {getEventPlace(eventPlace.id) !== "ava" && (
          <Menubar.MenuItem>
            <button onClick={() => navigate('/fotogallery')} className={pathname === "/fotogallery"  ? "active": ""}>
              Fotógaléria
            </button>
          </Menubar.MenuItem>
        )}
        {MenuList.filter((n, index) => getEventPlace(eventPlace.id) !== "ava" ? index === MenuList.length-2 : index > 1).map((k,n) => {return (
        <div key={n+1}>
            {k.dropddown_list.length === 0 ?
              <Menubar.MenuItem>
                <Link to={getEventPlaceUrl('/', k.id)} target="_blank">
                  <button>
                    {k.title}
                  </button>
                </Link>
              </Menubar.MenuItem>
            :
              <>
              {getEventPlace(eventPlace.id) !== "galeria" && (
                <Menubar.MenuItem>
                {(menuItemProps) => (
                  <Menubar.Submenu>
                    <Menubar.Submenu.Trigger
                      className={k.link === pathname ? "active": ""}
                      {...menuItemProps}
                    >

                      <div className="d-flex align-items-center">
                        {k.title}<img src={next} alt="tovabb" style={{marginLeft: "5px"}}/>
                      </div>
                    </Menubar.Submenu.Trigger>

                    <Menubar.Submenu.List >
                      {k.dropddown_list.filter(k => k.eventplace !== getEventPlace(eventPlace.id) && k.eventplace !== "ava").map((l,m) => {
                              return (
                        <Menubar.MenuItem key={m+1}>
                          {k.title === "Média" ?
                          <button onClick={() => window.open(l.link, '_blank')} style={{fontWeight: "300", fontStyle: "normal"}}>
                          {l.title}
                          </button>:
                          <Link to={getEventPlaceUrl(l.link, l.id)} target="_blank">
                            <button className={l.linkEnd === pathnameEnd  ? "active": ""} style={{fontWeight: "300", fontStyle: "normal"}}>
                            {l.title}
                            </button>
                          </Link>
                          }

                        </Menubar.MenuItem>
                      )})}
                    </Menubar.Submenu.List>
                  </Menubar.Submenu>
                )}
                </Menubar.MenuItem>
              )}

              </>

            }
        </div>
        )})}
        {getEventPlace(eventPlace.id) !== "ava" && (
          <Menubar.MenuItem>
            <Link to={getEventPlaceUrl(`/home`, 1)} target="_blank">
              <button>
                A Vértes Agorája
              </button>
            </Link>
          </Menubar.MenuItem>
        )}
        {getEventPlace(eventPlace.id) === "pmh" && (
          <Menubar.MenuItem>
            <button onClick={() => navigate('/nepmesepont')} className={pathname === "/nepmesepont"  ? "active": ""}>
            NépmesePont
            </button>
          </Menubar.MenuItem>
        )}
        {getEventPlace(eventPlace.id) === "ava" && (
          <Menubar.MenuItem>
            <button className="jegyvasarlas-button" onClick={() => window.open('https://www.jegy.hu/pfsearch?q=a+v%c3%a9rtes+agor%c3%a1ja&sgtype=pfhistoykeyword', '_blank')}>
              <span>Jegyvásárlás</span>
            </button>
          </Menubar.MenuItem>
        )}
        <Menubar.MenuItem>
          <button className="search-button" onClick={() => {dispatch(setSearchFunction())}} style={{marginRight: direction2 > 1248 ? 0 : 0}}>
            <img src={search} alt="Keresés ikon"/>
          </button>
        </Menubar.MenuItem>
      </div>
    </React.Fragment>
  );

  const HorizontalMenubar = (props) => {
    return (
      <Menubar  direction="horizontal" {...props}>
        <MockMenuItems />
      </Menubar>
    );
  };

  return (<>

    {direction2 > 810 && (

      <MDBNavbar expand='lg' light bgColor='light' aria-label={label}>
        <MDBContainer style={{maxWidth: "1008px", height: "100%"}} className='p-0 multilevel-navbar'>

          <div className=" d-flex justify-content-end align-items-center" style={{width: "100%"}}>

              <HorizontalMenubar aria-label={label} />

          </div>
        </MDBContainer>
      </MDBNavbar>

    )}
    {direction2 <= 810 && (<div>
      <div className="sidenav-bottom-respo">
        <MDBSideNavLink onClick={() => { navigate('/home'); }}>
          {eventPlace && (
            <img src={require("../../../assets/media/logos/" + getEventPlace(eventPlace.id) + "_logo.svg")} alt="ava-logo" height='36' loading='lazy'/>
          )}


        </MDBSideNavLink>
        <div className="d-flex">
        {getEventPlace(eventPlace.id) === "ava" && direction2 >= 500 &&(
            <MDBSideNavLink className="jegyvasarlas-button" onClick={() => window.open('https://www.jegy.hu/pfsearch?q=a+v%c3%a9rtes+agor%c3%a1ja&sgtype=pfhistoykeyword', '_blank')}>
              <span>Jegyvásárlás</span>
            </MDBSideNavLink>
        )}
        <MDBSideNavLink type="button" onClick={() => {setBasicOpen(!basicOpen); setIsMenu(true); setIsSubMenu(false); setIsSubsubMenu(false)}} className="sidenavButton">
          <img src={basicOpen === false ? hamburger : close} alt="hamburger"/>
        </MDBSideNavLink>
        </div>

      </div>
      <MDBSideNav isOpen={basicOpen} right getOpenState={(e) => setBasicOpen(e)} >
        <MDBSideNavMenu>
          {isMenu && (<>
            {getEventPlace(eventPlace.id) !== "ava" && (
              <MDBSideNavItem>
                <MDBSideNavLink
                  onClick={() => {setMenuText("Rólunk");
                                  setMenuList([{title: "Bemutatkozás", url: '/bemutatkozas', child: false, children: []}, {title: "Elérhetőségek", url: '/elerhetosegek', child: false, children: [], programCategoryId: null, hirekCategoryId: null}]);
                                  setIsSubMenu(true);
                    setTimeout(() => setIsMenu(false), 50);
                                 }}
                  className={pathname === "/bemutatkozas" || pathname === "/elerhetosegek" ? "active": ""}
                >
                  <div className="menu-title">
                    <span>
                      Rólunk
                    </span>
                    <img src={next} alt="tovabb"/>
                  </div>

                </MDBSideNavLink>
              </MDBSideNavItem>
            )}
            {eventpages && (<>
              {getEventPlace(eventPlace.id) !== "galeria" ?
              <MDBSideNavItem>
                <MDBSideNavLink
                  onClick={() => {
                    setMenuText("Programok");
                    if(getEventPlace(eventPlace.id) !== "ava"){
                      setMenuList(eventpages.filter((k,n) => k.onNotAva).map((l,m) => {
                        return {title: l.name, url: l.navigate, child: false, children: [], programCategoryId: l.programCategoryId, hirekCategoryId: null}}));
                    }else {
                      setMenuList(eventpages.map((l,m) => {
                        return {title: l.name, url: l.navigate, child: false, children: [], programCategoryId: l.programCategoryId, hirekCategoryId: null}}));
                    }

                    setIsSubMenu(true);
                    setTimeout(() => setIsMenu(false), 50);

                  }}
                  className={pathname === "/programok" || pathname === "/fixprogramok" ? "active": ""}

                >

                  <div className="menu-title">
                    <span>
                    Programok
                    </span>
                    <img src={next} alt="tovabb"/>
                  </div>
                </MDBSideNavLink>
              </MDBSideNavItem>
              :
              <MDBSideNavItem>
                <MDBSideNavLink onClick={() => {
                  setBasicOpen(!basicOpen);
                  navigate('fixprogramok/kovetkezoprogramok');
                  dispatch(setProgramCategoryId(null));
                                                    cookies.set('programCategoryId', {
                                                      programCategoryId: null
                                                    }, { path: '/'}
                                                  )}} className={pathname === "/programok" || pathname === "/fixprogramok" ? "active": ""}>
                  Korábbi kiállítások
                </MDBSideNavLink>
              </MDBSideNavItem>
              }
              </>
            )}

            {articleCategories && (
              <MDBSideNavItem>
                <MDBSideNavLink
                  className={pathname === "/hirek" || pathname === "/hirekfilter" ? "active": ""}
                  onClick={() => {setBasicOpen(!basicOpen); navigate('/hirek/hirek');}}
                >

                  <div className="menu-title">
                    <span>
                    Hírek
                    </span>
                  </div>
                </MDBSideNavLink>
              </MDBSideNavItem>
            )}
            {getEventPlace(eventPlace.id) === "ava" && (<>
              {infopages && (
                <MDBSideNavItem>

                      <MDBSideNavLink
                         onClick={() => {
                          setMenuText("Info");
                          setMenuList(infopages.map((l,m) => { return {
                            title: l.title,
                            url: l.children.length === 0 ? '/infosfooldal/' + l.id : null,
                            child: l.children.length === 0 ? false : true,
                            children: l.children.length === 0 ?  [] : l.children,
                            programCategoryId: null,
                            hirekCategoryId: null,
                            infoId: l.children.length === 0 ? l.id : null,
                            infoSource: l.children.length === 0 ? l.source : null,
                            parentId: l.children.length === 0 ? l.id : null,
                            pagetype: l.children.length === 0 ? l.pagetype : null,
                          }

                          }));




                          setIsSubMenu(true);
                          setTimeout(() => setIsMenu(false), 50);

                        }}
                        className={pathname === "/infos" || pathname === "/infosfooldal" ? "active": ""}

                      >

                        <div className="menu-title">
                          <span>
                          Info
                          </span>
                          <img src={next} alt="tovabb"/>
                        </div>
                      </MDBSideNavLink>
                </MDBSideNavItem>
              )}
            </>)}

            {getEventPlace(eventPlace.id) !== "ava" && (
              <MDBSideNavItem>
                <MDBSideNavLink onClick={() => {setBasicOpen(!basicOpen); navigate('/fotogallery')}} className={pathname === "/fotogallery"  ? "active": ""}>
                  Fotógaléria
                </MDBSideNavLink>
              </MDBSideNavItem>
            )}
            {MenuList.filter((n, index) => getEventPlace(eventPlace.id) !== "ava" ? index === MenuList.length-2 : index > 1).map((k,n) => {return (
            <div key={n+1}>
                {k.dropddown_list.length === 0 ?
                  <MDBSideNavItem>
                    <Link to={getEventPlaceUrl(k.link, k.id)} onClick={() => setBasicOpen(!basicOpen)}>
                      <MDBSideNavLink>{k.title}</MDBSideNavLink>
                    </Link>
                  </MDBSideNavItem>
                :
                  <MDBSideNavItem>
                        <MDBSideNavLink
                          onClick={() => {
                            setMenuText(k.title);

                            setMenuList(k.dropddown_list.filter(k => k.eventplace !== getEventPlace(eventPlace.id)).map((k,m) => {
                              return {  title: k.title,
                                        url: k.link,
                                        child: false,
                                        children: [],
                                        programCategoryId: null,
                                        hirekCategoryId: null,
                                        infoId: k.id,
                                        infoSource: k.source,
                                        parentId: null,
                                        kiemelt: k.kiemelt,
                                        font:k.font,
                                        eventplace: k.eventplace
                                      }}));
                            setIsSubMenu(true);
                            setTimeout(() => setIsMenu(false), 50);
                          }}
                          className={k.link === pathname ? "active": ""}
                        >

                          <div className="menu-title">
                            <span>
                            {k.title}
                            </span>
                            <img src={next} alt="tovabb"/>
                          </div>
                        </MDBSideNavLink>
                  </MDBSideNavItem>
                }
            </div>
            )})}
            {getEventPlace(eventPlace.id) !== "ava" && (
              <MDBSideNavItem>
                <Link to={ getEventPlaceUrl('/home', 1) } onClick={() => setBasicOpen(false)} target="_blank">
                  <MDBSideNavLink>
                    A Vértes Agorája
                  </MDBSideNavLink>
                </Link>
              </MDBSideNavItem>
            )}
            {getEventPlace(eventPlace.id) === "pmh" && (
              <MDBSideNavItem>
                <MDBSideNavLink onClick={() => {navigate('/nepmesepont')}} className={pathname === "/nepmesepont"  ? "active": ""}>
                NépmesePont
                </MDBSideNavLink>
              </MDBSideNavItem>
            )}
            <div>
              {getEventPlace(eventPlace.id) === "ava" && direction2 < 500 && (
                <MDBSideNavItem>
                  <MDBSideNavLink onClick={() => {setBasicOpen(!basicOpen); window.open('https://www.jegy.hu/pfsearch?q=a+v%c3%a9rtes+agor%c3%a1ja&sgtype=pfhistoykeyword', '_blank')}}>
                    <div className="menu-title">
                      <span>Jegyvásárlás</span>
                    </div>
                  </MDBSideNavLink>
                </MDBSideNavItem>
              )}
              <MDBSideNavItem>
                <MDBSideNavLink className="search-button" onClick={() => {setBasicOpen(!basicOpen); dispatch(setSearchFunction())}} style={{marginRight: 0}}>

                  <div className="menu-title">
                    <span>Keresés</span>
                  </div>
                </MDBSideNavLink>
              </MDBSideNavItem>
            </div>

          </>)}
          {isSubMenu && (
            <>
            <div className="submenu-title" onClick={() => {setIsMenu(true);
                                                           setTimeout(() => setIsSubMenu(false), 50);}} style={{cursor: "pointer"}}>
              <img src={back} alt="vissza"/>
              <span>{menuText}</span>
            </div>

            {menuList.map((k,n) => { return (
              <MDBSideNavItem key={n}>
                {menuText === "Média" ?
                <MDBSideNavLink
                  onClick={() => {

                      setBasicOpen(!basicOpen);
                      window.open(k.url, '_blank')
                      setIsMenu(true);
                      setIsSubMenu(false);
                  }}


                >
                  {k.title}
                </MDBSideNavLink>:
                <MDBSideNavLink
                onClick={() => {
                  if (k.pagetype === 'DINAMIC') {
                    navigate(`/${ k.infoSource }`);
                    setBasicOpen(!basicOpen);
                    return;
                  }

                  if(k.child === false){
                    setBasicOpen(!basicOpen);
                    navigate(k.url);
                    if(k.programCategoryId !== null){
                      dispatch(setProgramCategoryId(k.programCategoryId));
                      cookies.set('programCategoryId', {
                          programCategoryId: k.programCategoryId
                        }, { path: '/'}
                      );
                    }
                    if(k.infoId !== null && k.infoId !== undefined){
                      navigate(k.url);
                    }
                    if(k.eventplace !== undefined){
                      window.open(getEventPlaceUrl('/', k.infoId));
                    }
                    setIsMenu(true);
                    setIsSubMenu(false);

                  } else {
                    setSubMenuText(k.title);
                    setSubMenuList(k.children.map((l,m) => {return {
                      title: l.title,
                      url: '/infos/' + getReplaceText(k.title) + '/' + getReplaceText(l.title),
                      child: false,
                      children: [],
                      programCategoryId: null,
                      hirekCategoryId: null,
                      infoId: l.id,
                      infoSource: l.source,
                      parentId: null
                    }}))
                    setIsMenu(false);
                    setIsSubsubMenu(true);
                    setTimeout(() => setIsSubMenu(false), 50);

                  }
                }}
                className={pathname === "/programok" || pathname === "/fixprogramok" ? "active": ""}

              >
                {k.child === false ? k.title :
                <div className="menu-title">
                  <span>
                  {k.title}
                  </span>
                  <img src={next} alt="tovabb"/>
                </div> }
              </MDBSideNavLink>}
              </MDBSideNavItem>
            )})}
            </>
          )}
          {isSubsubMenu && (
            <>

             <div className="submenu-title" onClick={() => {setIsSubMenu(true);
                                                           setTimeout(() => setIsSubsubMenu(false), 50);}} style={{cursor: "pointer"}}>
                <img src={back} alt="vissza"/>
                <span>{subMenuText}</span>
              </div>
            {subMenuList.map((k,n) => {return (
              <MDBSideNavItem key={n}>
                <MDBSideNavLink
                  onClick={() => {
                    if(k.child === false){
                      setBasicOpen(!basicOpen);
                      navigate(k.url);
                      if(k.programCategoryId !== null){
                        dispatch(setProgramCategoryId(k.programCategoryId));
                        cookies.set('programCategoryId', {
                            programCategoryId: k.programCategoryId
                          }, { path: '/'}
                        );
                      }
                      if(k.infoId !== null && k.infoId !== undefined){
                        navigate(k.url);
                      }
                      setIsMenu(true);
                      setIsSubMenu(false);

                    }
                  }}
                  className={pathname === "/programok" || pathname === "/fixprogramok" ? "active": ""}

                >
                  {k.title}
                </MDBSideNavLink>
              </MDBSideNavItem>
            )})}
            </>
          )}
        </MDBSideNavMenu>
      </MDBSideNav>
      </div>
    )}

      </>
  );
};

export default NavbarComponentMultilevel