// react imports
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import 'react-calendar/dist/Calendar.css';
import moment from 'moment'
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
// CUSTOM COMPONENTS
import ProgramCard from "../components/cards/ProgramCard"
import CarouselComponent from '../components/carousel/CarouselComponent.js'
import tovabb from "../assets/media/icons/tovabb nyil.svg"
import share from "../assets/media/icons/share.svg"
import { RWebShare } from "react-web-share";

import { MDBContainer
          } from 'mdb-react-ui-kit';
import {BlurhashImage} from "../components/blurhash/BlurhashImage";
import {useSelector} from "react-redux";
import {OpenStreetMap} from "../components/openstreetmap/OpenStreetMap";
import {formatEventDate} from "../util/format-event-date";


const ProgramDetailPage = (props) => {

  const location = useLocation();
  const navigate = useNavigate();
  const { event, programCategoryId } = useSelector(state => state);

  moment.updateLocale('hu', {
    months : [
        "Január", "Február", "Március", "Április", "Május", "Június", "Július",
        "Augusztus", "Szeptember", "Október", "November", "December"
    ]
  }); 

  // STATES
  const [sliderImages, setSliderImages] = useState([]);
  const [open, setOpen] = useState(false);
  const [eventpageName, setEventpageName] = useState();
  const [direction, setDirection] = useState(window.outerWidth);

  const { eventTypes, guestEvents } = useSelector(state => state);

  useEffect(() => {
    if (programCategoryId !== null && programCategoryId !== undefined && eventTypes.length > 0) {
      setEventpageName(eventTypes.filter(k => k.id === programCategoryId)[0].name)
    }
  }, [
    eventTypes, programCategoryId
  ])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });

  }, [ location ]);

  const handleWindowSizeChange = () => {
    setDirection(window.visualViewport.width);
  };

    useEffect(() => {
      window.visualViewport.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.visualViewport.removeEventListener('resize', handleWindowSizeChange);
      };
    }, []);
  
  
  
  return (
    <>
    {event && (

      <>
      {direction > 810.98 ?
        <section className="programdetail-section" id ="programdetail-section" style={{minHeight: "calc(100vh - 617px"}}>
          <MDBContainer style={{maxWidth: "1920px"}} className='p-0'>
            <div className="table-div">
              <div className="left">
                <div className="programdetail-header">
                  <div className="program-background-image" id="program-background-image">
                    <BlurhashImage image={event.image} blurhash={event.imageBlurhash} />
                    <div className="datum-div">
                      <span>{ moment.unix(event.dateFrom).locale('hu').format('MMMM DD., ') + formatEventDate(event) }</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="program-detail-up">
                  <div className="propgram-title">
                    <span>{event.title}</span>
                  </div>
                  <div className="program-description">
                    <p className="title">Helyszín:</p>
                    <span className="text">{event.location?.address}</span>
                    <p className="title">Időpont:</p>
                    <span className="text">{formatEventDate(event)}</span>
                    <p className="title">Esemény kategória:</p>
                    {event.eventCategories  && (
                      <>
                        {event.eventCategories.map((k,n) => {
                          return (
                            <span className="text">{k.name}</span>
                          )
                        })}
                      </>
                    )}
                  </div>
                </div>
                <div className="program-detail-down">
                  <p>{event.ticketPrice}</p>
                  {event.ticketPurchaseLink !== null && (
                    <button type="button" className="muster-button kiemelt jegyvasarlas"onClick={() => window.open(event.ticketPurchaseLink, '_blank')}><span>Jegyvásárlás</span></button>
                  )}
                </div>
              </div>
            </div>
            <div className="table-div-2">
              <div className="left">
                <div className="up-div">
                      <div className="navigate-div" style={{ marginTop: 0 }}>
                        <button onClick={() => { navigate('/home')}}>Főoldal</button>
                        <img src={tovabb} alt="tovabb"/>
                        {location.state !== null && (
                          <button onClick={() => {navigate(
                            programCategoryId !== null && programCategoryId !== undefined ? 
                              '/programok/' + programCategoryId : '/fixprogramok/' + location.state?.linkText)}}>{programCategoryId !== null && programCategoryId !== undefined ?
                              eventpageName : location.state?.categoryName}</button>
                        )}
                      </div>
                      {event.relatedLinks && event.relatedLinks.length > 0 && (
                                  <div className="pb-4">
                                        {event.relatedLinks.map((k, n) => { return (
                                          <div className="loadmore-div" key={n}>
                                            <button  className="muster-button kiemelt" onClick={() => window.open(k.link, '_blank')}><span className="button-text">{k.title}</span></button>
                                          </div>
                                        )})}
                                  </div>
                      )}
                      <div className="programdetail-description" >
                        <div style={{maxWidth: "710px"}} dangerouslySetInnerHTML={{__html: event.description}}></div>
                        <div className="gallery">
                        {event.gallery && event.gallery.length > 0 &&(
                              <MDBContainer fluid className="gallery px-0 mb-5">
                                <div  className="gallery-title pb-4" >
                                  <span>Galéria</span>
                                </div>
                                <CarouselComponent gallery={event.gallery} openLightboxlick={() => {setSliderImages(event.gallery); setOpen(!open)}}/>
                              </MDBContainer>
                        )}
                        {event.youtubeEmbedUrl && (
                            <div className="video">
                              <iframe src={event.youtubeEmbedUrl + "?autoplay=0&controls=1&loop=0"}
                                  height={window.innerWidth/1920 > 1 ? 433 : (window.innerWidth/1920)*433} 
                                  width={window.innerWidth/1920 > 1 ? 
                                          770 : (window.innerWidth/1920)*770} allow="autoplay" title="myFrame"></iframe>
                            </div>
                        )}
                      </div>
                        <div className="share-button-div py-4">
                          <RWebShare
                              data={{
                                text: "Az alábbi linket osztották meg Önnel: ",
                                url: window.location.href,
                                title: "Megosztás",
                              }}
                              onClick={() => console.log("shared successfully!")}
                          >
                            <button type="button" className="muster-button kiemelt"><span>Megosztás </span><img src={share} alt=""/></button>
                          </RWebShare>
                        </div>
                      </div>
                </div>
                <div className="program-maps">
                  <p>Helyszín:</p>
                  <span>{event.location?.name}</span><br/>
                  <span>{event.location?.address}</span>
                  {(
                    <div className="map-keret" id="map-keret">
                      {(
                        <OpenStreetMap lat={event.location?.lat} lng={event.location?.lng} />
                      )}
                    </div>
                  )}
                
                </div>
              </div>
              
                <div className="right">
                  <div className="programdetail-body" style={{height: document.getElementById("programdetail-body-left")?.offsetHeight}}>
                    <div className="kozelgo-programok">
                      {guestEvents.filter(k => k.dateFrom > event.dateFrom).filter((k,index) => index < 2).length > 0 && (
                        <>
                          <div className="program-title pb-3" style={{cursor: "pointer"}} onClick={() => {navigate(
                              programCategoryId !== null && programCategoryId !== undefined ? 
                                '/programok/' + programCategoryId : '/fixprogramok/' + location.state?.linkText)}}>
                          
                            
                            <span>Közelgő programok</span>
                          </div>
                          {guestEvents.filter(k => k.dateFrom > event.dateFrom).filter((k,index) => index < 2).map((k,n) => {return (
                            <div key={n}>
                              <ProgramCard msg={k} categoryName={location.state?.categoryName} linkText={location.state?.linkText} />
                            </div>
                          )})}
                        </>
                      )}
                      
                        
                    </div>
                  </div>
                </div>
            </div>
          
          </MDBContainer>
        </section> :
        <section className="programdetail-section-respo" id ="programdetail-section-respo" style={{minHeight: "calc(100vh - 617px"}}>
          <MDBContainer style={{maxWidth: "1920px"}} className='p-0'>
            <div className="programdetail-header">
              
              <div className="program-background-image" id="program-background-image">
                  <img src={event.image} alt="program-background"/>
                  <div className="datum-div">
                    <span>{ moment.unix(event.dateFrom).locale('hu').format('MMMM DD., ') + formatEventDate(event) }</span>
                  </div>
              </div>
              <div className="navigate-div">
                <button onClick={() => { navigate('/home')}}>Főoldal</button>
                <img src={tovabb} alt="tovabb"/>
                {location.state !== null && (
                  <button onClick={() => {navigate(
                    programCategoryId !== null && programCategoryId !== undefined ? 
                      '/programok/' + programCategoryId : '/fixprogramok/' + location.state?.linkText)}}>{programCategoryId !== null && programCategoryId !== undefined ?
                      eventpageName : location.state?.categoryName}</button>
                )}
              </div>
              <div className="program-detail-up">
                <div className="propgram-title">
                  <span>{event.title}</span>
                </div>
              </div>
              <div className="program-detail-down">
                  <p>{event.ticketPrice}</p>
                  {event.ticketPurchaseLink !== null && (
                      <button type="button" className="muster-button kiemelt jegyvasarlas"onClick={() => window.open(event.ticketPurchaseLink, '_blank')}><span>Jegyvásárlás</span></button>
                  )}
              </div>
              <div className="up-div">
                {event.relatedLinks && event.relatedLinks.length > 0 && (
                  <div className="pb-4">
                        {event.relatedLinks.map((k, n) => { return (
                          <div className="loadmore-div" key={n}>
                            <button  className="muster-button kiemelt" onClick={() => window.open(k.link, '_blank')}><span className="button-text">{k.title}</span></button>
                          </div>
                        )})}
                  </div>
                )}
                <div className="programdetail-description">
                    <div style={{width: "100%"}} dangerouslySetInnerHTML={{__html: event.description}}></div>
                </div>
               
              </div>
              <div className="program-maps">
                <p>Helyszín:</p>
                <span>{event.location?.name}</span><br/>
                <span className="addr">{event.location?.address}</span>
                <div className="map-keret" id="map-keret">
                  <OpenStreetMap lat={event.location?.lat} lng={event.location?.lng} />
                </div>
              </div>
              <div className="program-description">
                <p className="title org">Helyszín:</p>
                <span className="text org">{event.location?.address}</span>
                <p className="title">Időpont:</p>
                <span className="text">{formatEventDate(event)}</span>
                <p className="title">Esemény kategória:</p>
                {event.eventCategories  && (
                  <>
                  {event.eventCategories.map((k,n) => {
                    return (
                      <span className="text">{k.name}</span>
                    )
                  })}
                  </>
                  
                )}
              </div>
              <div className="gallery">
                {event.gallery && event.gallery.length > 0 &&(
                      <MDBContainer fluid className="gallery px-0 mb-5">
                        <div  className="gallery-title pb-4" >
                          <span>Galéria</span>
                        </div>
                        <CarouselComponent gallery={event.gallery} openLightboxlick={() => {setSliderImages(event.gallery); setOpen(!open)}}/>
                      </MDBContainer>
                )}
                {event.youtubeEmbedUrl && (
                    <div className="video">
                      <iframe src={event.youtubeEmbedUrl + "?autoplay=0&controls=1&loop=0"}
                          height={window.innerWidth/1920 > 1 ? 433 : window.innerWidth > 810 ? (window.innerWidth/1920)*433: (window.innerWidth-50)*9/16} 
                          width={window.innerWidth/1920 > 1 ? 
                                  770 : window.innerWidth > 810 ? (window.innerWidth/1920)*770 : window.innerWidth-50} allow="autoplay" title="myFrame"></iframe>
                    </div>
                )}
              
              </div>
              <div className="kozelgo-programok">
                {guestEvents.filter(k => k.dateFrom > event.dateFrom).filter((k,index) => index < 2).length > 0 && (
                  <>
                    <div className="program-title pb-3">
                      <span onClick={ () => navigate(`/fixprogramok/kovetkezoprogramok`)}>Közelgő programok</span>
                    </div>
                    {guestEvents.filter(k => k.dateFrom > event.dateFrom).filter((k,index) => index < 2).map((k,n) => {return (
                      <div key={n}>
                        <ProgramCard msg={k}/>
                      </div>
                    )})}
                  </>
                )}
                
                  
              </div>
            </div>
          </MDBContainer>
        </section>}
      </>
      
    )}
    <Lightbox
        open={open}
        close={() => setOpen(false)}
        slides={sliderImages.map(k => {return ({src: k.imageUrl})})}
        plugins={[Captions, Fullscreen, Slideshow, Thumbnails, Video, Zoom]}
      />
    </>
  );
};

export default ProgramDetailPage;
