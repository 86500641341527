// REACT IMPORTS
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import NavbarComponent from "./NavbarComponentMultilevel"
import telefon from "../../../assets/media/icons/telefon ikon.svg"
import ora from "../../../assets/media/icons/ora ikon.svg"
import email from "../../../assets/media/icons/email ikon.svg"
import searchikon from "../../../assets/media/icons/Kereses ikon.svg"

import {
  MDBContainer, MDBInput
} from 'mdb-react-ui-kit';
import {useDispatch, useSelector} from "react-redux";
import {setSearchFunction} from "../../../redux/actions/setSearchFunction";
import {setSearchText} from "../../../redux/actions/setSearchText";



const Header = ({props}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const { eventPlace, isSearchFunction, searchText } = useSelector(state => state);

  const [direction, setDirection] = useState(window.outerWidth <= 500 ? "vertical" : "horizontal");
  const [direction2, setDirection2] = useState(window.outerWidth);

  const handleWindowSizeChange = () => {
      setDirection(window.visualViewport.width <= 500 ? "vertical" : "horizontal");
      setDirection2(window.visualViewport.width);
  };

  useEffect(() => {
    window.visualViewport.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.visualViewport.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const onSearch = () => {
    if(searchText !== "") {
      dispatch(setSearchFunction())
      navigate('/searchpage')
    }else {
      alert('Adjon meg keresési szöveget!')
    }
  }

    return (
      <header className="agora-header">
        {direction === "horizontal" && (
          <div className="information-bar">
            <MDBContainer style={{maxWidth: "1008px", height: "100%"}} className='p-0'>
              <div className="d-flex align-items-center justify-content-start" style={{height: "100%", paddingLeft: direction2 > 1248 ? 0 : '20px' }}>
                <div className="d-flex align-items-center justify-content-start">
                  <img className="info-ikon" src={telefon} alt="telefon"/>
                  <span className="info-text">
                    {eventPlace.phone}
                    
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-start">
                  <img className="info-ikon" src={email} alt="telefon"/>
                  <span className="info-text">
                  {eventPlace.email}
                    
                  </span>
                </div>
                <div className="d-flex align-items-center justify-content-start">
                  <img className="info-ikon" src={ora} alt="telefon"/>
                  <span className="info-text">{"Ma: " + eventPlace.openingHours?.todayOpen}</span>
                </div>
              </div>
            </MDBContainer>
          </div>
        )}
        <NavbarComponent />
        {isSearchFunction && (
                <div className="search-div" >
                  <div className="search-tag">
                    <MDBInput value={searchText}
                                    name='searchtext'
                                    type="text"
                                    onChange={(e) => dispatch(setSearchText(e.target.value))}
                                    label='Keresés'
                    onKeyDown={e => { if (e.key === "Enter") { onSearch() } }} />
                    <button className="datum-search-button" type="button"  
                          onClick={onSearch}
                    >
                      <img src={searchikon} alt="search ikon"/>
                    </button>
                  </div>
                <div className="close-tag" onClick={() => dispatch(setSearchFunction())}>
                </div>
              </div>
            )}
      </header>
    );
};

export default Header
