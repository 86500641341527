import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import 'react-calendar/dist/Calendar.css';
import moment from 'moment'
import SearchCard from "../components/cards/SearchCard"
import searchikon from "../assets/media/icons/Kereses ikon.svg"
import tovabb from "../assets/media/icons/tovabb nyil.svg"
import { MDBContainer, MDBCol, MDBRow, MDBInput, MDBNavbar, MDBNavbarNav, MDBNavbarItem, MDBNavbarLink } from 'mdb-react-ui-kit';
import {setSearchText} from "../redux/actions/setSearchText";
import {useDispatch, useSelector} from "react-redux";
import InterAxios from "../Service/InterAxios";
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const getAuthToken = () => cookies.get('authtoken')?.token;
const interAxios = new InterAxios('App', getAuthToken);

const SearchPage = () => {

    moment.updateLocale('hu', {
        months: [
            "Január", "Február", "Március", "Április", "Május", "Június", "Július",
            "Augusztus", "Szeptember", "Október", "November", "December"
        ]
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { searchText } = useSelector(state => state);

    // STATES
    const [fullItemList, setFullItemList] = useState([]);
    const [activeItem, setActiveItem] = useState(1);
    const [searchRespond, setSearchRespond] = useState([]);
    const [category, setCategory] = useState([]);
    const [pagination, setPagination] = useState();
    const [urlText, setUrlText] = useState("");
    const [direction, setDirection] = useState(window.outerWidth);

    const handleWindowSizeChange = () => {
        setDirection(window.visualViewport.width);
    };

    useEffect(() => {

        window.visualViewport.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.visualViewport.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    useEffect(() => {
        onGetSearchRespond();

    }, [searchText]);

    const onGetSearchRespond = () => {
        interAxios.get('/search?q=' + searchText, function (response) {
            setSearchRespond(response.data.sidenav)
            setActiveItem(1);
            setCategory(response.data.sidenav[0].category)
            onGetSearchRespondbyId('/search?q=' + searchText + "&category=" + response.data.sidenav[0].category + "&page=1");
            setUrlText('/search?q=' + searchText + "&category=" + response.data.sidenav[0].category + "&page=1")
        }, function (error) {
            console.log("errorFunction is running: " + error.response?.data)
        });
    }

    const onGetSearchRespondbyId = (url) => {
        interAxios.get(url, function (response) {
            setFullItemList(response.data.results);
            setPagination(response.data.paginator)
        }, function (error) {
            console.log("errorFunction is running: " + error.response?.data)
        });
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, []);

    return (
        <>
            {direction > 500 ?
                <section className="search-section" style={{paddingBottom: "85px", minHeight: "calc(100vh - 617px"}}>
                    <MDBContainer style={{maxWidth: "1008px"}} className='p-0'>
                        <div className="column left">
                            <MDBNavbar>
                                <MDBNavbarNav className='mr-auto mb-2 mb-lg-0'>
                                    {searchRespond?.map((k, n) => {
                                        return (
                                            <MDBNavbarItem onClick={() => {
                                                setActiveItem(n + 1);
                                                onGetSearchRespondbyId('/search?q=' + searchText + "&category=" + k.category + "&page=1");
                                                setUrlText('/search?q=' + searchText + "&category=" + k.category + "&page=1");
                                                setCategory(k.category)
                                            }} key={n}>
                                                <MDBNavbarLink active={activeItem === n + 1} href='#'>
                                                    {k.title + " (" + k.resultCount + ")"}
                                                </MDBNavbarLink>
                                            </MDBNavbarItem>
                                        )
                                    })}
                                </MDBNavbarNav>
                            </MDBNavbar>
                        </div>
                        <div className="column right">
                            <div className="navigate-div">
                                <button onClick={() => {
                                    navigate('/home')
                                }}>Főoldal
                                </button>
                                <img src={tovabb} alt="tovabb"/>
                                <button>Keresés</button>
                                <img src={tovabb} alt="tovabb"/>
                                <button>{searchRespond?.length || 0 > 1 ? searchRespond[activeItem - 1].title : ""}</button>
                            </div>
                            <div className="search-div">
                                <div className="search-tag">
                                    <MDBInput value={searchText}
                                              name='searchtext'
                                              type="text"
                                              onChange={(e) => dispatch(setSearchText(e.target.value))}
                                              label='Keresés'/>
                                    <button className="datum-search-button" type="button">
                                        <img src={searchikon} alt="search ikon"/>
                                    </button>
                                </div>
                            </div>
                            <div>

                                {fullItemList?.length > 0 && (
                                    <div>
                                        <div className="program-title">
                                            <span>{"Keresett kifejezés: " + searchText}</span>
                                        </div>
                                        <MDBRow className="m-0 programok-row">
                                            {fullItemList?.map((k, n) => {
                                                return (
                                                    <MDBCol xl="4" lg="4" md="6" className="programok-col" key={n}>
                                                        <SearchCard msg={k} category={category}/>
                                                    </MDBCol>
                                                )
                                            })}
                                        </MDBRow>
                                        {pagination && (
                                            <div className="paginator pt-2">
                                                <ul>
                                                    {pagination?.map((k, n) => {
                                                        return (

                                                            <li key={n}><a
                                                                className={parseInt(urlText.slice(-1)) === k.content ? "active" : ""}
                                                                onClick={() => {
                                                                    onGetSearchRespondbyId(k.url.replace("/api/v1.0", ""));
                                                                    setUrlText(k.url.replace("/api/v1.0", ""))
                                                                }}>{k.content}</a></li>
                                                        )
                                                    })}
                                                </ul>


                                            </div>
                                        )}

                                    </div>
                                )}

                            </div>
                        </div>
                    </MDBContainer>
                </section> :
                <section className="search-section-respo"
                         style={{paddingBottom: "85px", minHeight: "calc(100vh - 617px"}}>
                    <MDBContainer style={{maxWidth: "1284px"}} className='p-0'>

                        <div className="">
                            <div className="navigate-div">
                                <button onClick={() => {
                                    navigate('/home')
                                }}>Főoldal
                                </button>
                                <img src={tovabb} alt="tovabb"/>
                                <button>Keresés</button>
                                <img src={tovabb} alt="tovabb"/>
                                <button>{searchRespond?.length || 0 > 1 ? searchRespond[activeItem - 1].title : ""}</button>
                            </div>
                            <div className="search-div">
                                <div className="search-tag">
                                    <MDBInput value={searchText}
                                              name='searchtext'
                                              type="text"
                                              onChange={(e) => dispatch(setSearchText(e.target.value))}
                                              label='Keresés'/>
                                    <button className="datum-search-button" type="button">
                                        <img src={searchikon} alt="search ikon"/>
                                    </button>
                                </div>
                            </div>
                            <div className="program-title">
                                <span>{"Keresett kifejezés: " + searchText}</span>
                            </div>
                            <MDBNavbar>
                                <MDBNavbarNav className='mr-auto mb-2 mb-lg-0'>
                                    {searchRespond?.map((k, n) => {
                                        return (
                                            <MDBNavbarItem onClick={() => {
                                                setActiveItem(n + 1);
                                                onGetSearchRespondbyId('/search?q=' + searchText + "&category=" + k.category + "&page=1");
                                                setUrlText('/search?q=' + searchText + "&category=" + k.category + "&page=1");
                                                setCategory(k.category)
                                            }} key={n}>
                                                <MDBNavbarLink active={activeItem === n + 1} href='#'>
                                                    {k.title + " (" + k.resultCount + ")"}
                                                </MDBNavbarLink>
                                            </MDBNavbarItem>
                                        )
                                    })}
                                </MDBNavbarNav>
                            </MDBNavbar>
                            <div>
                                {fullItemList?.length > 0 && (
                                    <div>
                                        <MDBRow className="m-0 programok-row">
                                            {fullItemList?.map((k, n) => {
                                                return (
                                                    <MDBCol md="4" size="126" className="programok-col" key={n}>
                                                        <SearchCard msg={k} category={category}/>
                                                    </MDBCol>
                                                )
                                            })}
                                        </MDBRow>
                                        {pagination && (
                                            <div className="paginator pt-2">
                                                <ul>
                                                    {pagination?.map((k, n) => {
                                                        return (

                                                            <li key={n}><a
                                                                className={parseInt(urlText.slice(-1)) === k.content ? "active" : ""}
                                                                onClick={() => {
                                                                    onGetSearchRespondbyId(k.url.replace("/api/v1.0", ""));
                                                                    setUrlText(k.url.replace("/api/v1.0", ""))
                                                                }}>{k.content}</a></li>
                                                        )
                                                    })}
                                                </ul>


                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </MDBContainer>
                </section>}
        </>
    );
};

export default SearchPage;
